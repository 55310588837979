.input {
    width: 100%;
    padding: 20px;
    border: 2px solid #444;
    border-radius: 16px;
    color: rgba(255, 255, 255, 0.6);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    box-sizing: border-box;
    background-color: transparent;
}

.input[readonly] {
    cursor: not-allowed;
    background-color: transparent;
}

.inputSelec {
    border: transparent;
    margin-bottom: 0;
    width: 80%;
}

.selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 2px solid #444;
    border-radius: 16px;
    box-sizing: border-box;
}

.dropdownIcon {
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.description {
    color: #FFF;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.fileAttachBlock {
    display: inline-flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    flex-shrink: 0;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 16px;
    border-radius: 16px;
    border: 1px solid #FF4D00;
    box-sizing: border-box;

    input[type="file"] {
        display: none;
    }

    .fileAttachText {
        color: #FFF;
        font-family: Manrope;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 18px */
    }
}

.attachedFiles {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    padding-left: 16px;
    box-sizing: border-box;

    .attachedFile {
        width: 100%;
        display: inline-flex;
        gap: 16px;
    }
}