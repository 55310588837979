.menuPage {
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    border-radius: 32px;
    background: #262626;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;

    .layout {
        position: absolute;

        height: 60%;
        width: 100%;
        top: 0;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../images/png/FlyingCoins.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 0;
            filter: grayscale(100%);
        }
    }

    .headerBlock {
        width: 100%;
        text-align: center;
        margin-bottom: 2%;
        z-index: 1;
    }

    .headerName {
        color: #F9F9F9;
        font-family: "Druk Wide Cyr";
        //font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
    }

    .buttonsBlock {
        z-index: 1;
        margin-left: 20px;
        margin-right: 20px;
    }
}