$btn-one-normal-bg: #3E3E3E;
$btn-one-hover-bg: #616161;
$btn-one-disabled-bg: #2D2D2D;

$btn-two-normal-bg: #FF4D00;
$btn-two-hover-border-color: rgba(255, 77, 0, 0.20);
$btn-two-disabled-bg: #2D2D2D;

$btn-three-normal-bg: rgba(255, 77, 0, 0.10);
$btn-three-normal-color: #FF4D00;
$btn-three-hover-bg: rgba(255, 77, 0, 0.20);
$btn-three-disabled-bg: #2D2D2D;
$btn-three-disabled-color: rgba(255, 255, 255, 0.40);

$button-height: 60px;
$button-padding-y: 23px;
$button-padding-x: 30px;
$button-radius: 16px;
$button-font-family: Manrope;
$button-font-size: 18px;
$button-font-weight: 400;
$button-line-height: 100%;

// Общие переходы
$transition-duration: 0.2s;
$transition-timing: ease-in-out;

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $button-height;
  padding: $button-padding-y $button-padding-x;
  border-radius: $button-radius;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  line-height: $button-line-height;
  text-align: center;
  color: #FFF;
  width: 100%;
  border: none;
  transition: all $transition-duration $transition-timing;
  cursor: pointer;
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }
}

.btnOne {
  background-color: $btn-one-normal-bg;
  margin-bottom: 10px;
  transition: background-color $transition-duration $transition-timing,
    color $transition-duration $transition-timing;

  &:hover {
    background-color: $btn-one-hover-bg;
  }

  &:disabled {
    background-color: $btn-one-disabled-bg;
    color: #FFFFFF66;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(97, 97, 97, 0.3);
  }
}

.btnTwo {
  background-color: $btn-two-normal-bg;
  transition: background-color $transition-duration $transition-timing,
    color $transition-duration $transition-timing;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    border-radius: $button-radius;
    background-color: $btn-two-hover-border-color;
    opacity: 0;
    transition: opacity $transition-duration $transition-timing;
    z-index: -1;
  }

  &:hover::before {
    opacity: 1;
  }

  &:disabled {
    background-color: $btn-two-disabled-bg;
    color: #FFFFFF66;

    &::before {
      display: none;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 77, 0, 0.3);
  }
}

.btnThree {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: $button-height;
  padding: 0 60px 0 59px;
  border-radius: $button-radius;
  background-color: $btn-three-normal-bg;
  color: $btn-three-normal-color;
  font-size: 16px;
  font-weight: $button-font-weight;
  line-height: $button-line-height;
  text-align: center;
  width: 100%;
  transition: background-color $transition-duration $transition-timing,
    color $transition-duration $transition-timing,
    box-shadow $transition-duration $transition-timing;

  &:hover {
    background-color: $btn-three-hover-bg;
    color: #FFF;
  }

  &:disabled {
    background-color: $btn-three-disabled-bg;
    color: $btn-three-disabled-color;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 77, 0, 0.2);
  }
}