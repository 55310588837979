.profileScreen {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    padding-top: 0;
    background-color: #1e1e1e;
    height: 100%;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
    gap: 20px;
}

.avatar {
    width: 160px;
    height: 160px;
    background-color: #444;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-top: 20px;
    align-self: center;
}

.input {
    width: 100%;
    padding: 20px;
    border: 1px solid #444;
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    background-color: transparent;
}

.input[readonly] {
    cursor: not-allowed;
    background-color: transparent;
}

.inputSelec {
    border: transparent;
    margin-bottom: 0;
    width: 80%;
}

.selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid #444;
    border-radius: 16px;
    box-sizing: border-box;
}

.dropdownIcon {
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.error {
    color: #ff4d4f;
    font-size: 12px;
    margin-bottom: 12px;
}

.editButton {
    padding: 10px 20px;
    background-color: #ff6600;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.editButton:hover {
    background-color: #e55a00;
}