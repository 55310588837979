.content {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    overflow: hidden;

    .walletBlock {
        margin: 0;
    }

    .remainChecks {
        color: #FFF;
        font-family: Manrope;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
}