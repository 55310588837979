.input {
    width: 100%;
    padding: 20px;
    border: 2px solid #444;
    border-radius: 16px;
    color: rgba(255, 255, 255, 0.6);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    box-sizing: border-box;
    background-color: transparent;
}

.input[readonly] {
    cursor: not-allowed;
    background-color: transparent;
}

.inputSelec {
    border: transparent;
    margin-bottom: 0;
    width: 80%;
}

.selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 2px solid #444;
    border-radius: 16px;
    box-sizing: border-box;
}

.dropdownIcon {
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}