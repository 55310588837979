.requestPage {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 32px;
  background: #262626;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  box-sizing: border-box;


  .imageBlock {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;

    img {}
  }

  .successTitle {
    text-align: center;
    align-self: center;
    border: none;
  }
}

.request {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 100%;
}

.requestBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 16px;
  background: rgba(50, 50, 50, 0.50);
  box-sizing: border-box;

  .requestBlockLabel {
    color: rgba(255, 255, 255, 0.60);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .requestBlockId {
    color: #FFF;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

.requestBtnPosition {
  box-sizing: border-box;
}

.layout {
  position: absolute;
  background: #262626;
  height: 25%;
  width: 100%;
  top: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../images/png/save.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
    filter: grayscale(100%);
  }
}

.headerRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;

  .headerTitle {
    color: #FFF;
    font-family: "Druk Wide Cyr";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 0;
    margin-top: 30px;
    padding-top: 0;
  }

  .infoIconBlock {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 200px;
    border: 2px solid rgba(255, 255, 255, 0.10);

  }
}

.headerDescription {
  color: rgba(255, 255, 255, 0.60);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
}

.input {
  width: 100%;
  padding: 20px;
  border: 1px solid #444;
  border-radius: 16px;
  color: #fff;
  font-size: 14px;
  box-sizing: border-box;
  background-color: transparent;
}

.input[readonly] {
  cursor: not-allowed;
  background-color: transparent;
}

.inputSelec {
  border: transparent;
  margin-bottom: 0;
  width: 80%;
}

.selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid #444;
  border-radius: 16px;
  box-sizing: border-box;
}

.dropdownIcon {
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.exchangeRate {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: #2C2C2C;
  color: rgba(255, 255, 255, 0.60);

  padding-top: 10px;
  padding-bottom: 10px;

  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.switch {
  position: absolute;
  left: 50%; // центрирование по горизонтали
  transform: translateX(-50%) translateY(20%); // компенсация ширины самого элемента
  z-index: 2; // чтобы элемент был поверх других
  display: flex;
  width: 24px;
  height: 24px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.10);
  background: #191919;
  box-shadow: 0px 8px 16px 0px rgba(38, 38, 38, 0.06);

  // Позиционирование между блоками
  margin-top: calc(-72px / 2); // 93px - высота recieveOrGiveBlock
}

.exchangeBlock {
  position: relative;
  z-index: 1;


  .recieveOrGiveBlock {
    height: 93px;
    flex-shrink: 0;
    border-radius: 16px;
    background: transparent;
    margin-bottom: 10px;
    border-radius: 16px;
    border: 2px solid rgba(255, 255, 255, 0.10);

    .labelDescription {

      color: rgba(255, 255, 255, 0.60);

      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 14px */
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 9px;
    }
  }

}

.walletBlock {
  margin: 0;
}

.agreementBlock {
  margin-left: 20px;
  margin-bottom: 15px;
  color: #FFF;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    a {
      color: #FFF;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 6px;
    border-radius: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;

    &:checked {
      background: rgba(255, 77, 0, 0.20);
      ;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background: rgba(255, 77, 0, 0.2);
        border-radius: 4px;
        mask: url('../../images/Check.svg') no-repeat center;
      }
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }

    &:focus {
      outline: none;
      border-color: rgba(255, 77, 0, 0.5);
    }
  }
}

.buttonsBlock {
  flex-shrink: 0;
  padding-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  margin-bottom: 10px;
}