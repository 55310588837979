.requestItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    align-items: start;
    border-radius: 16px;
    background: #323232;
    padding: 16px;
    max-width: 90%;
    box-sizing: border-box;

}

.requestInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.requestTitle {
    color: #FFF;
    font-family: "Druk Wide Cyr";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.requestType {
    color: rgba(255, 255, 255, 0.60);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.requestAmount {
    display: inline-flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.10);
    color: #FFF;

    text-align: right;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.requestDate {
    color: rgba(255, 255, 255, 0.60);

    text-align: right;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.requestStatInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 4px;
    padding-top: 10px;
    padding-right: 15px;
    border-top: solid 0.1px rgba(255, 255, 255, 0.10);
}

.requestStatus {
    color: #FFF;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    &.status0 {
        color: #fff;
    }

    &.status1 {
        color: #EA3B3E;
    }

    &.status2 {
        color: #49BC82;
    }
}