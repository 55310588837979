.inputContainer {
  /*position: relative;*/
  width: 100%;
  padding-bottom: 20px;
  transition: all 0.3s ease;

  &.shake {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .currencyIcon {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.05);
    margin-left: 20px;
    margin-right: 8px;
  }

  .currencyLabel {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #FFF;

    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 24px */
  }

  .field {
    /*position: relative;*/
    display: flex;
    align-items: center;
    margin-right: 20px;
    justify-content: end;
  }

  .inputField {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    color: #FFF;
    text-align: right;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    /* 24px */

    &::placeholder {
      opacity: 0;
    }
  }

  .placeholder {
    color: #FFF;
    position: absolute;
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }

  .currency-dropdown {
    position: fixed;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    width: 85%;
    background: #262626;
    border-radius: 12px;
    padding: 16px;
    z-index: 1000;

    //transition: transform 0.5s ease, opacity 0.5s ease;
    // Начальное положение для анимации
    &.openBonusMenu-enter {
      transform: translateX(-50%) translateY(100vh);
    }

    // Анимация появления
    &.openBonusMenu-enter-active {
      transform: translateX(-50%) translateY(-50%);
      transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    // Состояние перед исчезновением
    &.openBonusMenu-exit {
      transform: translateX(-50%) translateY(-50%);
    }

    // Анимация исчезновения
    &.openBonusMenu-exit-active {
      transform: translateX(-50%) translateY(100vh);
      transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }


    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    .modal-title {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 24px;
      line-height: 100%;
    }

    .currency-group {
      &+.currency-group {
        margin-top: 16px;
      }

      .group-title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        margin-bottom: 8px;
      }

      .currency-option {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        cursor: pointer;
        border-radius: 12px;
        transition: background-color 0.2s ease;
        margin-bottom: 8px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        &.selected {
          background-color: rgba(255, 255, 255, 0.15);
        }

        .currency-content {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: space-between;
        }

        .currency-icon {
          width: 32px;
          height: 32px;
          border-radius: 200px;
          background: #363636;
          margin-right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .currency-name {
          color: #FFF;
          font-size: 16px;
          font-weight: 500;
        }

        .radio-indicator {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid rgba(255, 255, 255, 0.3);
          margin-left: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: transparent;
            transition: background-color 0.2s ease;
          }
        }

        &.selected .radio-indicator {
          border-color: #FF5F1F;

          &::after {
            background: #FF5F1F;
          }
        }

      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  //transition: opacity 0.5s ease;
}

.openBonusFon-enter {
  opacity: 0;
}

.openBonusFon-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.openBonusFon-exit {
  opacity: 1;
}

.openBonusFon-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}