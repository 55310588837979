.menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    background-color: #1e1e1e;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #333;
}

.menuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #aaa;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
        color: #FF4D00; // Будет видно в desktop-версии. В мобильной Telegram MiniApp hover может не работать.
    }
}

.menuItemActive {
    color: #FF4D00;
}

.menuLabel {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
}