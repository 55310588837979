.App {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    background: #262626;
}

.content {
    flex-grow: 1;
    padding-bottom: 64px;
    max-height: calc(100vh - 64px);
    overflow-y: hidden;
}

.full {
    padding-bottom: 0;
    max-height: 100vh;
}

@font-face {
    font-family: Manrope;
    src: url(./fonts/Manrope/Manrope-VariableFont_wght.ttf);
}

@font-face {
    font-family: Druk Wide Cyr;
    src: url(./fonts/Druk\ Wide\ Cyr/fonts/drukwidecyr-bold.ttf);
}