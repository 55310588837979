@keyframes pulse-border {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    70% {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    20%, 60% {
      transform: translateX(-5px);
    }
    40%, 80% {
      transform: translateX(5px);
    }
  }
  
  .wallet-input {
    width: 100%;
  }
  
  .custom-input {
    &-container {
      position: relative;
      width: 100%;
      margin-bottom: 0.5rem;
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      background-color: transparent;
      transition: all 0.3s ease;
  
      &.shake {
        animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
  
      &:focus-within {
        animation: pulse-border 1.5s infinite;
      }
  
      &.error {
        border-color: #ff4d4f !important;
      }
    }
  
    &-wrapper {
      display: flex;
      align-items: center;
      padding: 1rem;
    }
  
    &-field {
      &-wrapper {
        position: relative;
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
  
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 1.1rem;
      font-weight: 500;
      color: #FFFFFF;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
        opacity: 1;
      }
    }
  
    &-placeholder {
      position: absolute;
      left: 0;
      pointer-events: none;
      font-size: 1.25rem;
      font-weight: 700;
      opacity: 0.5;
      transition: opacity 0.2s ease;
    }
  
    &-error {
      color: #ff4d4f;
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }
  }