.infoPage {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 32px;
    background: #262626;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
    box-sizing: border-box;

    .headerRow {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        .headerTitle {
            color: #FFF;
            font-family: "Druk Wide Cyr";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            margin-bottom: 0;

            padding-top: 0;
        }

        .infoIconBlock {
            display: flex;
            width: 30px;
            height: 30px;
            padding: 6px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 200px;
            border: 2px solid rgba(255, 255, 255, 0.10);

        }
    }

    .headerDescription {
        flex: 1;
        min-height: 0;
        color: rgba(255, 255, 255, 0.60);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        overflow: scroll;
        white-space: pre-line;
        /* 19.2px */
    }

    .buttonsBlock {
        flex-shrink: 0;
        padding-top: 10px;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        margin-bottom: 10px;
    }
}