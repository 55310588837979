.referralScreen {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 32px;
  background: #262626;
  display: flex;
  flex-direction: column;
  position: relative;

  .copyNotification {
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(52, 199, 89, 0.2);
    color: #34C759;
    padding: 12px 24px;
    border-radius: 16px;
    z-index: 100;
    text-align: center;
    animation: notification-appear 0.3s ease-out;
  }

  @keyframes notificationAppear {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }

  .layout {
    border-radius: 16px;
    background: rgba(62, 62, 62, 0.50);
    width: 100%;
    top: 20%;

    .emptyState {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
      padding-top: 20px;

      .image {
        width: 130px;
        height: 130px;
        background-image: url("../../images/png/ref.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        filter: grayscale(100%);
      }

      .emptyText {
        color: #808080;
        font-size: 14px;
        text-align: center;
        margin-top: 16px;
      }
    }
  }

  .content {
    position: relative;
    z-index: 1;
    padding: 24px;
    padding-top: 0;
    margin-top: 0;
    flex: 1;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .headerRow {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 40px;
      margin-bottom: 30px;

      .headerTitle {
        color: #FFF;
        font-family: "Druk Wide Cyr";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;

      }

      .infoIconBlock {
        display: flex;
        width: 30px;
        height: 30px;
        padding: 6px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 200px;
        border: 2px solid rgba(255, 255, 255, 0.10);

      }
    }



    .referralHeader {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
      gap: 12px;


      .referralCode {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 12px;
        border-radius: 16px;
        justify-content: space-between;
        border: 2px solid rgba(255, 255, 255, 0.10);

        span {
          color: #FFF;

          text-align: right;
          font-family: Manrope;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 48px;
        }

        input {
          background: transparent;
          border-radius: 12px;
          color: #F9F9F9;
          border: none;
          font-size: 16px;

          &:focus {
            outline: none;
          }
        }

        .copyButton {
          background: transparent;
          border: none;
          border-radius: 12px;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:active {
            background: #404040;
          }
        }
      }

      .referralCodeBalance {
        background-color: #323232;
        border-color: transparent;
      }

      .referralTop {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        align-items: center;
      }

      .referralTitle {
        color: rgba(255, 255, 255, 0.60);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .referralTitleRight {
        color: rgba(255, 255, 255, 0.60);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: right;
      }

    }



    .referralsList {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 100%;
      overflow: scroll;
    }
  }
}