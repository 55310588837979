.referralItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  width: 100%;
  padding: 16px;
  border-bottom: solid 0.1px rgba(255, 255, 255, 0.10);
  box-sizing: border-box;


  .userInfo {
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: center;
    gap: 12px;

    .userAvatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #262626;
    }


  }

  .userName {
    color: #FFF;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

  }

  .balance {
    color: #FFF;
    width: 31%;
    text-align: right;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    align-self: center;
  }
}