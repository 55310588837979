.invoicePayment {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 32px;
  background: #262626;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  box-sizing: border-box;

  .layout {
    position: absolute;
    opacity: 0.7;
    height: 35%;
    width: 100%;
    top: 0;

    &::before {
      content: "";
      position: absolute;
      top: 10%;
      left: 30%;
      width: 100%;
      height: 100%;
      //background-image: url("../../images/png/blocks.png");
      box-shadow: 0 0 50px 40px #262626 inset;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 0;
      filter: grayscale(100%);
    }
  }

  .invoicePaymentHeader {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;

    .infoIconBlock {
      display: flex;
      width: 30px;
      height: 30px;
      padding: 6px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 200px;
      border: 2px solid rgba(255, 255, 255, 0.10);

    }
  }

  .invoicePaymentTitle {
    color: #FFF;
    font-family: "Druk Wide Cyr";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 0;
    margin-top: 40px;
    padding-top: 0;
    //font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  .headerDescription {
    color: rgba(255, 255, 255, 0.60);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */
  }

  .labelBigBtn {
    color: #FFF;

    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .invoicePaymentBigButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    flex-shrink: 0;

    .invoicePaymentBigButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      padding: 30px;
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      flex-shrink: 0;
      border-radius: 16px;
      background: #3E3E3E;
      cursor: pointer;


    }
  }

  .feePaymentBigButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 30px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: transparent;
    cursor: pointer;
  }
}