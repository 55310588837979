.requestPage {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 32px;
    background: #262626;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
    box-sizing: border-box;

    .imageBlock {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;

        img {}
    }

    .successTitle {
        text-align: center;
        align-self: center;
        border: none;
    }

    .request {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        height: 100%;
    }

    .requestBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        border-radius: 16px;
        background: rgba(50, 50, 50, 0.50);
        box-sizing: border-box;

        .requestBlockLabel {
            color: rgba(255, 255, 255, 0.60);
            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }

        .requestBlockId {
            color: #FFF;
            font-family: Manrope;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }
    }

    .requestBtnPosition {
        box-sizing: border-box;
    }
}

.headerDescription {
    color: rgba(255, 255, 255, 0.60);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */
}

.selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid #444;
    border-radius: 16px;
    box-sizing: border-box;
}