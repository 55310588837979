.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .advantagesBlock {
        flex-shrink: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        box-sizing: border-box;

        .advantageItem {
            display: flex;
            flex-direction: row;
            gap: 10px;
            width: 100%;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 6px;
            border-radius: 200px;
            background: #323232;
            box-sizing: border-box;
        }
    }

    .qaBlock {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .qaItem {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .questionText {
                color: rgba(255, 255, 255, 0.60);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }

            .answerText {
                white-space: pre-line;
                color: #FFF;
                font-family: Manrope;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }
    }

    .qUndelinedBlock {
        margin-top: 65px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .qUnderlinedItem {
            width: 100%;
            display: flex;
            flex-direction: column;

            .questionUnderlinedText {
                color: rgba(255, 255, 255, 0.60);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 10px;
            }

            .answerUnderlinedText {
                white-space: pre-line;
                color: #FFF;
                font-family: Manrope;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                padding-bottom: 15px;
                border-bottom: solid 1px rgba(255, 255, 255, 0.10);
                margin-bottom: 15px;

            }
        }
    }
}