.invoicePayment {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 32px;
    background: #262626;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
    box-sizing: border-box;

    .layout {
        position: absolute;
        opacity: 0.7;
        height: 35%;
        width: 100%;
        top: 0;

        &::before {
            content: "";
            position: absolute;
            top: 10%;
            left: 30%;
            width: 100%;
            height: 100%;
            //background-image: url("../../images/png/blocks.png");
            box-shadow: 0 0 50px 40px #262626 inset;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 0;
            filter: grayscale(100%);
        }
    }

    .invoicePaymentHeader {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;

        .infoIconBlock {
            display: flex;
            width: 30px;
            height: 30px;
            padding: 6px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 200px;
            border: 2px solid rgba(255, 255, 255, 0.10);

        }
    }

    .invoicePaymentTitle {
        color: #FFF;
        font-family: "Druk Wide Cyr";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 0;
        margin-top: 40px;
        padding-top: 0;
        //font-family: Roboto, Helvetica, Arial, sans-serif;
    }

    .headerDescription {
        color: rgba(255, 255, 255, 0.60);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
    }

    .invoicePaymentDataBlocks {
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        overflow: hidden;

        .invoicePaymentDataBlock {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;
            padding: 0;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            border: none;
            overflow-y: auto;
            background: transparent;
            cursor: pointer;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, 0.1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 2px;
            }
        }
    }

    .buttonsBlock {
        flex-shrink: 0;
        padding-top: 10px;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        margin-bottom: 10px;
    }
}