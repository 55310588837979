.requestsScreen {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 0;
    background-color: #262626;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
    max-width: 100%;
    height: 100%;
}

h1 {
    color: #FFF;
    font-family: "Druk Wide Cyr";
    font-size: 24px;
    font-style: normal;
    padding-top: 40px;
    font-weight: 700;
    line-height: 100%;

}

.requestsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    max-width: 100%;
}