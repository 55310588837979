@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  20%,
  60% {
    transform: translateX(-5px);
  }

  40%,
  80% {
    transform: translateX(5px);
  }
}

.textInputContainer {
  position: relative;
  margin: auto;
  margin-bottom: 0;
  transition: all 0.3s ease;
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.10);
  background: transparent;
  width: 100%;
  box-sizing: border-box;


}

.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.textInputWrapper {
  display: flex;
  align-items: flex-start;
  padding: 20px;
}

.textInputFieldWrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.textInputField {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.60);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 0;

  &::placeholder {
    opacity: 0;
  }

}

.textInputPlaceholder {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.60);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  opacity: 0.5;
  transition: opacity 0.2s ease;

  &.multiline {
    top: 0;
  }
}

.multiline {
  line-height: 1.5;
  padding-top: 0;
  padding-bottom: 0;
}



.textInputLabelWrapper {
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
}

.textInputLabel {
  font-weight: 500;
  white-space: nowrap;
}

.textInputFileAttach {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }
}

.textInputAttachedFiles {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.textInputAttachedFile {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #f5f5f5;
  margin-bottom: 0.25rem;

  .fileDelete {
    margin-right: 0.5rem;
    font-size: 1.25rem;
  }

  .fileName {
    font-size: 0.775rem;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 25ch;
  }
}