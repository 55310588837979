.content {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow: hidden;

  .fieldsBlock {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 0;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border: none;
    overflow-y: auto;
    background: transparent;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
    }
  }
}