@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.select-dialog {
  position: relative;
  background: #262626;
  width: 88%;
  border-radius: 12px;
  overflow: hidden;
  padding: 16px;
  animation: slideIn 0.3s ease-out;
  z-index: 1000;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .modal-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    line-height: 100%;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-out;
  }

  &-dialog {
    background: #262626;
    width: 90%;
    max-width: 500px;
    border-radius: 12px;
    overflow: hidden;
    animation: slideIn 0.3s ease-out;
    z-index: 1000;
  }

  &-header {
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &-search {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    input {
      width: 100%;
      padding: 0.75rem;
      border: 0px solid;
      border-radius: 6px;
      background: rgba(62, 62, 62, 0.50);
      font-size: 1rem;
      transition: all 0.2s ease;
      box-sizing: border-box;

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-content {
    max-height: 50vh;
    overflow-y: auto;
  }

  &-list {
    padding: 0.5rem 0;
  }

  &-item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.85;
    }

    &.selected {
      font-weight: 500;
    }
  }

  &-empty {
    padding: 2rem;
    text-align: center;
    font-style: italic;
    opacity: 0.85;
  }

  &-footer {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &-close {
    padding: 0.5rem 1rem;
    border: 1px solid;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    background: transparent;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.85;
    }
  }

  .select-option {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.2s ease;
    margin-bottom: 8px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.selected {
      background-color: rgba(255, 255, 255, 0.15);
    }

    .select-content {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
    }

    .select-icon {
      width: 32px;
      height: 32px;
      border-radius: 200px;
      background: #363636;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .select-name {
      color: #FFF;
      font-size: 16px;
      font-weight: 500;
    }

    .radio-indicator {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.3);
      margin-left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: transparent;
        transition: background-color 0.2s ease;
      }
    }

    &.selected .radio-indicator {
      border-color: #FF5F1F;

      &::after {
        background: #FF5F1F;
      }
    }

  }
}


.select-dialog-content {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}