.notification-modal {
    position: fixed;
    left: 50%; 
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    width: 85%;
    background: #262626;
    border-radius: 12px;
    padding: 24px;
    z-index: 1000;
  
    &.openBonusMenu-enter {
      transform: translateX(-50%) translateY(100vh);
    }
  
    &.openBonusMenu-enter-active {
      transform: translateX(-50%) translateY(-50%);
      transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  
    &.openBonusMenu-exit {
      transform: translateX(-50%) translateY(-50%);
    }
  
    &.openBonusMenu-exit-active {
      transform: translateX(-50%) translateY(100vh);
      transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  
    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0.7;
      
      &:hover {
        opacity: 1;
      }
    }
  
    .notification-content {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      margin-top: 8px;
    }
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  
  .openBonusFon-enter {
    opacity: 0;
  }
  
  .openBonusFon-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  
  .openBonusFon-exit {
    opacity: 1;
  }
  
  .openBonusFon-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }