.footerBlock {
    margin-top: 4px;
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.60);
    text-align: left;
    font-family: Manrope;
    //font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    /* 12px */
    bottom: 0;
}